<template>
  <div id="rivers">
    <Hero/>
    <div class="rivers-section">
      <h1>Destinations - {{destination.name}}</h1>
      <div v-for="location in locations" :key="location.id">
        <PropertyCardSm :name="location.name" :url="location.url" :src="location.src"/>
      </div>
     </div> 
    <FollowUs/>
    <BookNow/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import PropertyCardSm from '@/components/PropertyCardSm.vue'
import FollowUs from '@/components/FollowUs.vue'
import BookNow from '@/components/BookNow.vue'
import locations from '../data/destinations.json'
export default {
  components: { 
    Hero, 
    PropertyCardSm,
    FollowUs,
    BookNow
  },
  data() {
    return {
      locations: null,
      destination: null
    };
  },
    created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      const fetchedId = this.$route.params.id;
      this.destination = locations.find(c => c.id == fetchedId)
      this.locations = locations.find(c => c.id == fetchedId).rivers
      if (!this.locations.length) return this.$router.push('/404')
    }
  }
}
</script>


<style lang="scss">
#rivers {
  .rivers-section {
    width: 65%;
    margin: auto;
    h1 {
      text-align: left;
      margin-top: 50px;
    }
  }
  @media only screen and (max-width: 500px) {
    .rivers-section {
      width: 90%;
      margin: auto;
    }
  }
}
</style>