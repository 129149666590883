<template>
  <div id="property-card-small">
    <div class="property-img" :style="{ backgroundImage: 'url(' + require('@/assets/'+src+'.jpg') + ')' }">
    </div>
    <div class="property-text">
    <h3>{{name}}</h3>
    <router-link class="see-more" :to="url">See More &gt;</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertyCardSm',
  props:  {
    name: String, 
    url: String, 
    src: String
  }
}
</script>

<style scoped lang="scss">
#property-card-small {
  border: 3px solid black;
  margin: 20px 0px;
  .property-img {
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .property-text {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
  }
}
</style>
